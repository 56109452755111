<template>
  <div>
    <div class="switch-it-container" :class="backgroundClass" @click="switchIt">
      <div class="switch-it" :class="positionClass"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComposantSwitch",
  model: {
    prop: "status",
    event: "switch",
  },
  props: {
    status: { default: false },
  },
  methods: {
    switchIt() {
      this.$emit("switch", !this.status);
    },
  },
  computed: {
    positionClass() {
      return "position-" + (this.status ? "on" : "off");
    },
    backgroundClass() {
      return "background-" + (this.status ? "on" : "off");
    },
  },
};
</script>

<style lang="scss">
$height: 17px;

.switch-it-container {
  cursor: pointer;
  width: $height * 2.5 + 4;
  padding: 2px;
  border-radius: 20px;
  border: 1px solid rgb(139, 139, 139);
  transition: background 0.3s;
  /* height: 25px; */
}
.background-on {
  background-color: rgb(144, 255, 144);
}
.background-off {
  background-color: rgb(255, 176, 170);
}
.switch-it {
  width: $height;
  height: $height;
  border-radius: 50%;
  position: relative;
  transition: left 0.3s;
  background-color: white;
}
.position-off {
  left: 0;
}
.position-on {
  left: $height * 1.5-2px;
}
</style>
